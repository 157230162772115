import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#379683",
    },
    secondary: {
      main: "#05386B",
    },
    default: {
      main: "#8EE4AF",
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  colors: {
    green: "#379683",
    darkBlue: "#05386B",
    grey: "#f2f2f2",
    yellow: "#fac32d",
  },
});

export default theme;
