import React, { useState, useEffect } from "react";

import Alert from "@mui/material/Alert";
import useAlert from "../../hooks/useAlert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const AlertBox = () => {
  const [open, setOpen] = useState(false);
  const { alert, resetAlertMessage } = useAlert();

  useEffect(() => {
    if (alert.message) {
      setOpen(true);
      setTimeout(() => {
        resetAlertMessage();
      }, 5000);
    }
  }, [alert]);
  return alert.message ? (
    <div className="alert-box">
      <Collapse in={open}>
        <Alert
          severity={alert.type || "info"}
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          onClick={() => {
            setOpen(false);
            setTimeout(() => {
              resetAlertMessage();
            }, 500);
          }}
          sx={{ mb: 2 }}
        >
          {alert.message}
        </Alert>
      </Collapse>
    </div>
  ) : (
    ""
  );
};

export default AlertBox;
