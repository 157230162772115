import React from "react";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import useLoading from "../../hooks/useLoading";

const LoadingBox = () => {
  const { isLoading } = useLoading();
  return isLoading ? <div className="loading">Loading&#8230;</div> : "";
};

export default LoadingBox;
