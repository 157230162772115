import {
  setToStorage,
  getFromStorage,
  clearFromStorage,
} from "../utils/storage";

const user = getFromStorage("USER_DATA"),
  token = getFromStorage("ACCESS_TOKEN_DATA"),
  refresh_token = getFromStorage("REFRESH_TOKEN_DATA");

const initialAuth = {
  token: token ? token : null,
  user: user ? JSON.parse(user) : {},
  refresh_token: refresh_token ? refresh_token : null,
};

const setUser = (state, payload) => {
  setToStorage("USER_DATA", JSON.stringify(payload));
  return { ...state, user: { ...payload } };
};

const setAccessTokenData = (state, payload) => {
  setToStorage("ACCESS_TOKEN_DATA", payload);
  return { ...state, token: payload };
};

const setRefreshTokenData = (state, payload) => {
  setToStorage("REFRESH_TOKEN_DATA", payload);
  return { ...state, token: payload };
};

const resetAuthData = () => {
  clearFromStorage("USER_DATA");
  clearFromStorage("ACCESS_TOKEN_DATA");
  clearFromStorage("REFRESH_TOKEN_DATA");
  return {
    token: null,
    user: {},
  };
};

const authReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER_DATA":
      return setUser(state, action.payload);
    case "SET_ACCESS_TOKEN_DATA":
      return setAccessTokenData(state, action.payload);
    case "SET_REFRESH_TOKEN_DATA":
      return setRefreshTokenData(state, action.payload);
    case "RESET_AUTH_DATA":
      return resetAuthData();
    default:
      throw new Error();
  }
};

export { initialAuth, authReducer, resetAuthData };
